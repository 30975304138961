#styleOverridesId {
  overflow-x: hidden;

  * {
    font-family: "Unify Sans", Arial, Helvetica, sans-serif !important;
  }
  
  .ubsc_outer-wrap {
    background-color: #fff;
    min-height: calc(100vh - 64px);
  }

  .ubsc_status-check-wrapper {
    @media (min-width: 767px) and (max-width: 940px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    > span:first-child {
      position: relative;
      display: block;
      min-height: 296px;
      background: linear-gradient(to right, #000, #00278e);
      padding: 24px 0 !important;
      text-align: center;
      overflow: hidden;

      > div {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 1340px;
        padding: 0 24px !important;
        text-align: left;
        margin: auto;
      }
    }

    > span:nth-child(2) {
      background-color: black;
      display: block;

      form {
        position: relative;
        z-index: 2;
      }
    }
    
  }
  
  .ubsc_search-form-wrapper {
    background: transparent !important;

    form {
      max-width: 1040px;

      @media (max-width: 1099px) {
        flex-direction: column;

        .ubsc_input-wrapper {
          width: 100%;
          margin-bottom: 20px !important;
        }
      }
    }
  }

  .ubsc_input-wrapper {
    label {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.25px;
      color: #fff;
      margin-bottom: 8px;
      
      &,
      span {
        font-weight: 600;
      }
    }

    input[type="text"] {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.25px;
      color: #404040;
      padding: 8px 16px;
      height: 40px;
      font-weight: 400;
      background: #fff;
      border: 1px solid #404040;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }

  .ubsc_search-form-submit-button-wrapper {
    @media (min-width: 992px) {
      margin-top: 29px;
    }
  }

  .ubsc_status-check-button {
    text-align: center;
    color: #fff;
    padding: 9px 40px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    font-weight: 600;
    height: auto;

    &,
    &:hover {
      background-color: #0046ff !important;
      border: 2px solid #0046ff !important;
    }

    &:focus { 
      border: 2px solid #FFFFFF !important;
      box-shadow: 0px 0px 0px 3px #0046ff !important;
    }
  }

  .ubsc_status-check-title {
    position: relative;
    z-index: 2;
    color: #fff !important;
    font-size: 40px;
    line-height: 1.25;

    @media (max-width: 599px) {
      font-size: 28px;
    }
  }

  .ubsc_directory-grid {
    max-width: 1100px;
    padding-top: 10px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 32px;
      line-height: 1.25;
      text-align: center;
      color: #404040 !important;
      font-weight: 600;
      margin-bottom: 55px;
    }

    .ubsc_directory-logo-wrapper {
      margin-bottom: 35px;

      @media (max-width: 399px) {
        width: 33.33%;
      }

      @media (min-width: 400px) and (max-width: 599px) {
        width: 25%;
      }
    }

    .ubsc_directory-logo-image {
      width: 55px;
      height: 55px;
    }

    .ubsc_directory-logo-text {
      color: #404040;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.25;
      margin-top: 7px;

      @media (max-width: 599px) {
        font-size: 16px;
      }
    }
  }

  .ubsc_results-top-box,
  .ubsc_results-top-box-results-wrapper-wrapper {
    background: linear-gradient(to right, #000, #00278e);
    max-width: 100% !important;
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    
    span {
      color: #fff !important;
    }
  }

  .ubsc_results-top-box-results-wrapper-wrapper {
    margin-top: -20px;
  }

  .ubsc_links-row-link {
    position: relative;
    z-index: 2;
    max-width: 330px;
    width: 100%;
    justify-content: space-between;
  }

  #ubsc_share-result-link,
  #ubsc_pdf-export-link,
  .ubsc_change-search-data {
    img {
      height: 20px;
      width: auto;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      font-weight: 600;
      color: #fff !important;
      text-decoration: underline;
    }
  }
  
  .ubsc_change-search-data {
    > span {
      font-size: 16px !important;
    }
  }

  #ubsc_pdf-export-link {
    img {
      transform: translateY(1px);
    }
  }

  .ubsc_results-top-box-results-wrapper {
    max-width: 1040px !important;
    padding-bottom: 53px;
    min-height: 400px;
    margin: auto;
    height: auto !important;
    
    @media (min-width: 500px) {
      padding-left: 30px;
    }
  }

  .ubsc_results-top-box-address-row {
    @media (max-width: 991px) {
      align-items: center;
      flex-direction: column;
    }
    
    @media (min-width: 992px) {
      align-items: flex-end;
    }

    > div {
      font-size: 32px;
      line-height: 1.25;
      font-weight: 600;
      color: #fff !important;
    }

    .ubsc_change-search-data {
      margin-bottom: 5px;
      white-space: nowrap;
    }
  }

  .ubsc_results-top-box-header {
    position: relative;
    z-index: 2;
    font-size: 12px;
    line-height: 1.25;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
    
    &,
    span,
    div {
      color: #fff !important;
    }
  }

  .ubsc_results-top-box-percentage-box {
    position: relative;
    width: 160px;
    height: 100px;
    background: #fff;
    border-radius: 4px;
    border: 0;
    padding: 9px;
    
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    
    > span {
      display: block;
      margin-top: -5px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.25;
      text-align: center;
      color: #404040 !important;
      text-transform: lowercase;

      :first-line {
        text-transform: capitalize;
      }
    }
  }

  .ubsc_results-top-box-percentage-box-wrapper {
    min-width: 160px;

    @media (max-width: 767px) {
      max-height: 100% !important;
    }
  }

  .ubsc_pie-chart-shadow-wrapper {
    display: none;
  }

  .chartjs-render-monitor {
    @media (min-width: 768px) {
      width: 483px !important;
      height: 290px !important;
      transform: translateX(-50px);
    }

    @media (min-width: 768px) and (max-width: 991px) {
      transform: translateX(-15vw);
    }
  }

  .ubsc_percentage-number {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    color: #404040 !important;
    margin-bottom: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 4px 0px 0px 4px;
      width: 6px;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
    }
    
    &.ubsc_missing {
      &:before {
        background: #D60401;
      }
    }

    &.ubsc_incorrect {
      &:before {
        background: #FF9101;
      }
    }

    &.ubsc_perfect {
      &:before {
        background: #8FD16A;
      }
    }
  }

  .ubsc_results-top-box-chart-label {
    margin: auto;
    text-align: center;

    &,
    span {
      color: #fff !important;
      font-size: 20px;
      letter-spacing: 0.18px;
      line-height: 1.25;
      font-weight: 600;
      padding-bottom: 20px;
    }
  }

  .ubsc_results-top-box-results {
    min-width: 425px;
  }

  .ubsc_results-top-box-results-cta-wrapper {
    @media (max-width: 767px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    h3 {
      color: #fff !important;
      font-size: 32px;
      line-height: 1.25;
    }
  }

  .ubsc_results-top-box-arrow {
    display: none;
  }

  .ubsc_results-top-box-hr {
    max-width: 1040px;
  }

  .ubsc_links-row-link.ubsc_share {
    margin-bottom: 34px;
  }

  .ubsc_results-table-wrapper {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .ubsc_directory-cell {
    @media (max-width: 767px) {
      text-align: center !important;
      width: 100% !important;
      padding-left: 0 !important;

      img {
        display: inline-block;
        margin: auto;
      }
    }
  }

  hr.ubsc_results-top-box-hr {
    border-top: 1px solid #fff;
  }

  .ubsc_results-table-header-title {
    h2 {
      display: none;
    }

    p,
    p > span {
      font-size: 32px;
      line-height: 1.25;
      text-align: center;
      color: #404040 !important;
      font-weight: 600;
    }
  }
  .ub_circle_progress {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px;
  }
}